import React from "react";
import RegionalMusicTeachersPage from "../../../components/regional-music-teachers-page/regional-music-teachers-page";

const Page = () => (
  <RegionalMusicTeachersPage area="Stevenage" salary="£23,500">
    <p>
      Rocksteady Music School is the world's leading rock and pop music service for
      primary schools, teaching band lessons to tens of thousands of children every
      week, helping to inspire the next generation to discover their love of
      music while growing their confidence along the way.
    </p>
    <p>
      Our mission is to empower as many children as possible and transform their
      lives through progressive and inclusive music education. This is where you
      come in, if you are a confident Stevenage-based musician looking for work
      or your dream music career, and want to inspire the next generation of
      musicians or even an existing music teacher looking for a job with purpose
      and support, we would love to receive an application from you.
    </p>
  </RegionalMusicTeachersPage>
);

export default Page;
